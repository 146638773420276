<template>
  <slide-over-right
    :id="sorId"
    editType="vehicle-model"
    v-on:showModal="showModal"
  >
    <loading :active.sync="isLoading"></loading>

    <div class="flex flex-wrap px-6 pt-12 w-full">
      <div class="items-center  panel-title header-title">
        {{ titleText }}
      </div>
    </div>

    <div class="px-6">
      <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
        <form
          @submit.prevent="handleSubmit(confirmSubmit)"
          enctype="multipart/form-data"
        >
          <UploadAvatar
            v-model="form.vehicle_image"
            :current="form.vehicle_image"
            :class="`mt-5`"
          />
          <div class="flex items-center pt-2">
            <div class="pr-2 w-1/2">
              <text-input
                v-if="roleType === 'owner'"
                rules="required"
                   :label="
                $t(
                  'components.vehicleModelManagement.addEdit.steps.profile.title.name'
                )
              "
                placeholder="e.g. Segway MAX Plus"
                v-model="form.title"
              />
              <text-input
                v-else
                rules="required"
                        :label="
                $t(
                  'components.vehicleModelManagement.addEdit.steps.profile.title.modelName'
                )
              "
                placeholder="e.g. Segway MAX Plus"
                v-model="form.name"
              />
            </div>
            <div class="pl-2 w-1/2">
              <AppInput
                v-model="form.iot_protocol"
                type="richselect"
            :name="
                $t(
                  'components.vehicleModelManagement.addEdit.steps.profile.title.iotProtocol'
                )
              "
              :label="
                $t(
                  'components.vehicleModelManagement.addEdit.steps.profile.title.iotProtocol'
                )
              "
                rules="required"
                :disabled="roleType === 'owner' ? true : false"
                   :placeholder="
                  $t(
                    'components.vehicleModelManagement.addEdit.steps.profile.placeholder.iotProtocol'
                  )
                "
                value-attribute="value"
                text-attribute="display_name"
                :isInfoEnabled="false"
                :options="iotProtocols"
              />
            </div>
          </div>

          <div class="flex items-center pt-2">
            <div class="pr-2 w-1/2">
              <AppInput
                v-model="form.lock_manufacturer"
                type="richselect"
                :name="
                $t(
                  'components.vehicleModelManagement.addEdit.steps.profile.title.lockManufacture'
                )
              "
                :label="
                $t(
                  'components.vehicleModelManagement.addEdit.steps.profile.title.lockManufacture'
                )
              "
                rules="required"
                :disabled="roleType === 'owner' ? true : false"
                 :placeholder="
                  $t(
                    'components.vehicleModelManagement.addEdit.steps.profile.placeholder.lockManufacture'
                  )
                "
                value-attribute="id"
                text-attribute="name"
                :isInfoEnabled="false"
                :options="lockManufactures"
              />
            </div>
            <div class="pl-2 w-1/2">
              <AppInput
                v-model="form.vehicle_manufacturer"
                type="richselect"
                     :name="
                $t(
                  'components.vehicleModelManagement.addEdit.steps.profile.title.vehicleManufacture'
                )
              "
                 :label="
                $t(
                  'components.vehicleModelManagement.addEdit.steps.profile.title.vehicleManufacture'
                )
              "
                rules="required"
                :disabled="roleType === 'owner' ? true : false"
                 :placeholder="
                  $t(
                    'components.vehicleModelManagement.addEdit.steps.profile.placeholder.vehicleManufacture'
                  )
                "
                value-attribute="id"
                text-attribute="name"
                :isInfoEnabled="false"
                :options="vehicleManufactures"
              />
            </div>
          </div>

          <div class="flex items-center pt-2">
            <div class="pr-2 w-1/2">
              <text-input
                rules=""
                      :label="$t(
                  'components.vehicleModelManagement.addEdit.steps.profile.title.seatCapacity'
                )
              "
                type="number"
                placeholder="e.g. 3"
                v-model="form.seat_capacity"
              />
            </div>

            <div class="pl-2 w-1/2">
              <ValidationProvider
                :name="
                $t(
                  'components.vehicleModelManagement.addEdit.steps.profile.title.swappableBatteryStatus'
                )
              "
                rules=""
                v-slot="{ errors }"
              >
                <input-label
                  :error="errors[0]"
                    :text="
                  $t(
                    'components.vehicleModelManagement.addEdit.steps.profile.title.swappableBatteryStatus'
                  )
                "
                />
                <t-rich-select
                  placeholder="e.g. True"
                  :options="swapOptions"
                  valueAttribute="value"
                  textAttribute="text"
                  :hideSearchBox="true"
                  :variant="{ danger: !!errors[0] }"
                  v-model="form.swappable_battery"
                />
                <input-error-item :message="errors[0]" />
              </ValidationProvider>
            </div>
          </div>
          <div class="flex items-center pt-2">
            <div class="pr-2 w-1/2">
              <text-input
                rules=""
                       :label="$t(
                  'components.vehicleModelManagement.addEdit.steps.profile.title.maxSpeed'
                )
              "
                type="number"
                placeholder="e.g. 25"
                step="0.01"
                v-model="form.max_speed"
              />
            </div>
            <div class="pl-2 w-1/2">
              <ValidationProvider
                       :name="$t(
                  'components.vehicleModelManagement.addEdit.steps.profile.title.maxSpeedUnit'
                )
              "
                rules=""
                v-slot="{ errors }"
              >
                <input-label :error="errors[0]" :text="$t(
                  'components.vehicleModelManagement.addEdit.steps.profile.title.maxSpeedUnit'
                )
              " />
                <t-rich-select
                  placeholder="e.g. kmph"
                  :options="speedUnitOptions"
                  valueAttribute="value"
                  textAttribute="text"
                  :hideSearchBox="true"
                  :variant="{ danger: !!errors[0] }"
                  v-model="form.max_speed_unit"
                />
                <input-error-item :message="errors[0]" />
              </ValidationProvider>
            </div>
          </div>
          <div class="flex items-center pt-2">
            <div class="pr-2 w-1/2">
              <text-input
                rules=""
                       :label="$t(
                  'components.vehicleModelManagement.addEdit.steps.profile.title.mileage'
                )
              "
                type="number"
                placeholder="e.g. 100"
                v-model="form.range"
              />
            </div>
            <div class="pl-2 w-1/2">
              <ValidationProvider
              :name="$t(
                  'components.vehicleModelManagement.addEdit.steps.profile.title.mileageUnit'
                )
              "
                rules=""
                v-slot="{ errors }"
              >
                <input-label :error="errors[0]"   :text="$t(
                  'components.vehicleModelManagement.addEdit.steps.profile.title.mileageUnit'
                )
              " />
                <t-rich-select
                  placeholder="e.g. Mile"
                  :options="mileageOptions"
                  valueAttribute="value"
                  textAttribute="text"
                  :hideSearchBox="true"
                  :variant="{ danger: !!errors[0] }"
                  v-model="form.range_unit"
                />
                <input-error-item :message="errors[0]" />
              </ValidationProvider>
            </div>
          </div>
          <div class="flex items-center pt-2">
            <div class="pr-2 w-1/2">
              <text-input
                rules=""
                :label="$t(
                  'components.vehicleModelManagement.addEdit.steps.profile.title.motorPowerRating'
                )
              "
                placeholder="e.g. 350"
                v-model="form.motor_power_rating"
              />
            </div>
            <div class="pl-2 w-1/2">
              <ValidationProvider
            :name="$t(
                  'components.vehicleModelManagement.addEdit.steps.profile.title.motorPowerRatingUnit'
                )
              "
                rules=""
                v-slot="{ errors }"
              >
                <input-label
                  :error="errors[0]"
                    :text="$t(
                  'components.vehicleModelManagement.addEdit.steps.profile.title.motorPowerRatingUnit'
                )
              "
                />
                <t-rich-select
                  :options="motorPowerUnitOptions"
                  valueAttribute="value"
                  textAttribute="text"
                  :hideSearchBox="true"
                  :variant="{ danger: !!errors[0] }"
                  v-model="form.motor_power_rating_unit"
                />
                <input-error-item :message="errors[0]" />
              </ValidationProvider>
            </div>
          </div>
          <div class="flex items-center pt-2">
            <div class="pr-2 w-1/2">
              <text-input
                rules=""
                 :label="$t(
                  'components.vehicleModelManagement.addEdit.steps.profile.title.batteryCapacity'
                )
              "
                placeholder="e.g. 864"
                v-model="form.battery_capacity"
              />
            </div>
            <div class="pl-2 w-1/2">
              <ValidationProvider
                    :name="$t(
                  'components.vehicleModelManagement.addEdit.steps.profile.title.batteryCapacityUnit'
                )
              "
                rules=""
                v-slot="{ errors }"
              >
                <input-label :error="errors[0]" :text="$t(
                  'components.vehicleModelManagement.addEdit.steps.profile.title.batteryCapacityUnit'
                )
              " />
                <t-rich-select
                  :options="batteryUnitOptions"
                  valueAttribute="value"
                  textAttribute="text"
                  :hideSearchBox="true"
                  :variant="{ danger: !!errors[0] }"
                  v-model="form.battery_capacity_unit"
                />
                <input-error-item :message="errors[0]" />
              </ValidationProvider>
            </div>
          </div>
          <div class="flex items-center pt-2">
            <div class="pr-2 w-1/2">
              <text-input
                rules=""
               :label="$t(
                  'components.vehicleModelManagement.addEdit.steps.profile.title.chargingTime'
                )
              " 
                placeholder="e.g. 7"
                v-model="form.charging_time"
              />
            </div>
            <div class="pl-2 w-1/2">
              <ValidationProvider
                  :name="$t(
                  'components.vehicleModelManagement.addEdit.steps.profile.title.chargingTimeUnit'
                )
              " 
                rules=""
                v-slot="{ errors }"
              >
                <input-label :error="errors[0]"         :text="$t(
                  'components.vehicleModelManagement.addEdit.steps.profile.title.chargingTimeUnit'
                )
              " />
                <t-rich-select
                  :options="chargingTimeUnitOptions"
                  valueAttribute="value"
                  textAttribute="text"
                  :hideSearchBox="true"
                  :variant="{ danger: !!errors[0] }"
                  v-model="form.charging_time_unit"
                />
                <input-error-item :message="errors[0]" />
              </ValidationProvider>
            </div>
          </div>
          <div class="flex items-center pt-2">
            <div class="pr-2 w-1/2">
              <text-input
                rules=""
                :label="$t(
                  'components.vehicleModelManagement.addEdit.steps.profile.title.vehicleWeight'
                )
              "
                placeholder="e.g. 7"
                v-model="form.net_weight"
              />
            </div>
            <div class="pl-2 w-1/2">
              <ValidationProvider
                   :name="$t(
                  'components.vehicleModelManagement.addEdit.steps.profile.title.vehicleWeightUnit'
                )
              "
                rules=""
                v-slot="{ errors }"
              >
                <input-label :error="errors[0]"    :text="$t(
                  'components.vehicleModelManagement.addEdit.steps.profile.title.vehicleWeightUnit'
                )
              " />
                <t-rich-select
                  :options="weightUnitOptions"
                  valueAttribute="value"
                  textAttribute="text"
                  :hideSearchBox="true"
                  :variant="{ danger: !!errors[0] }"
                  v-model="form.net_weight_unit"
                />
                <input-error-item :message="errors[0]" />
              </ValidationProvider>
            </div>
          </div>
          <div class="flex items-center pt-2">
            <div class="pr-2 w-1/2">
              <text-input
                rules=""
                     :label="$t(
                  'components.vehicleModelManagement.addEdit.steps.profile.title.loadCapacity'
                )
             "
                placeholder="e.g. 7"
                v-model="form.payload_max_weight"
              />
            </div>
            <div class="pl-2 w-1/2">
              <ValidationProvider
                    :name="$t(
                  'components.vehicleModelManagement.addEdit.steps.profile.title.loadCapacityUnit'
                )
              "
                rules=""
                v-slot="{ errors }"
              >
                <input-label :error="errors[0]"       :text="$t(
                  'components.vehicleModelManagement.addEdit.steps.profile.title.loadCapacityUnit'
                )
              " />
                <t-rich-select
                  :options="weightUnitOptions"
                  valueAttribute="value"
                  textAttribute="text"
                  :hideSearchBox="true"
                  :variant="{ danger: !!errors[0] }"
                  v-model="form.payload_max_weight_unit"
                />
                <input-error-item :message="errors[0]" />
              </ValidationProvider>
            </div>
          </div>
          <div class="flex items-center pt-2">
            <div class="pr-2 w-1/2">
              <text-input
                rules=""
                :label="$t(
                  'components.vehicleModelManagement.addEdit.steps.profile.title.frontWheelDiameter'
                )
              "
                placeholder="e.g. 10"
                v-model="form.wheel_diameter"
              />
            </div>
            <div class="pl-2 w-1/2">
              <text-input
                rules=""
                 :label="$t(
                  'components.vehicleModelManagement.addEdit.steps.profile.title.rearWheelDiameter'
                )
              "
                placeholder="e.g. 7"
                v-model="form.rear_wheel_diameter"
              />
            </div>
          </div>
          <div class="flex items-center pt-2">
            <div class="pr-2 w-1/2">
              <ValidationProvider
                  :name="$t(
                  'components.vehicleModelManagement.addEdit.steps.profile.title.wheelDiameterUnit'
                )
              "
                rules=""
                v-slot="{ errors }"
              >
                <input-label :error="errors[0]"  :text="$t(
                  'components.vehicleModelManagement.addEdit.steps.profile.title.wheelDiameterUnit'
                )
              " />
                <t-rich-select
                  :options="wheelDiameterUnitOptions"
                  valueAttribute="value"
                  textAttribute="text"
                  :hideSearchBox="true"
                  :variant="{ danger: !!errors[0] }"
                  v-model="form.wheel_diameter_unit"
                />
                <input-error-item :message="errors[0]" />
              </ValidationProvider>
            </div>
            <div class="pl-2 w-1/2">
              <text-input
                rules=""
                :label="$t(
                  'components.vehicleModelManagement.addEdit.steps.profile.title.frontForkType'
                )
              "
                placeholder=""
                v-model="form.front_fork_type"
              />
            </div>
          </div>
          <div class="flex items-center pt-2">
            <div class="pr-2 w-1/2">
              <text-input
                rules=""
                     :label="$t(
                  'components.vehicleModelManagement.addEdit.steps.profile.title.frontBrakeTypes'
                )
              "
                placeholder=""
                v-model="form.front_brake_types"
              />
            </div>
            <div class="pl-2 w-1/2">
              <text-input
                rules=""
                         :label="$t(
                  'components.vehicleModelManagement.addEdit.steps.profile.title.rearBrakeTypes'
                )
              "
                placeholder=""
                v-model="form.rear_brake_types"
              />
            </div>
          </div>
          <div class="flex items-center pt-2">
            <div class="pr-2 w-1/2">
              <text-input
                rules=""
                        :label="$t(
                  'components.vehicleModelManagement.addEdit.steps.profile.title.kickStandType'
                )
              "
                placeholder=""
                v-model="form.kickstand_type"
              />
            </div>
            <div class="pl-2 w-1/2">
              <ValidationProvider
                       :name="$t(
                  'components.vehicleModelManagement.addEdit.steps.profile.title.exposedCables'
                )
              "
                rules=""
                v-slot="{ errors }"
              >
                <input-label :error="errors[0]" text="Exposed Cables" />
                <t-rich-select
                  :options="[
                    { text: 'True', value: true },
                    { text: 'False', value: false },
                  ]"
                  valueAttribute="value"
                  textAttribute="text"
                  :hideSearchBox="true"
                  :variant="{ danger: !!errors[0] }"
                  v-model="form.exposed_cables"
                />
                <input-error-item :message="errors[0]" />
              </ValidationProvider>
            </div>
          </div>
          <!-- <section>
            <div class="flex items-center my-5 text-sm font-bold text-gray-500">
              <span>Add Specs</span>
              <div
                class="col-span-1 ml-2 cursor-pointer focus:text-gray-400"
                @click="onAddSpecField"
              >
                <i class="fas fa-plus-circle" style="color: black"></i>
              </div>
            </div>

            <div
              class="flex items-center"
              v-for="(item, index) in specs"
              :key="index"
            >
              <div class="w-11/12">
                <text-input
                  rules=""
                  :label="`Spec No. ${index + 1}`"
                  type="text"
                  v-model="specs[index]"
                />
              </div>
              <div class="flex justify-center w-1/12 item-center">
                <div
                  class="col-span-1 cursor-pointer focus:text-gray-400"
                  @click="onRemoveSpecField(index)"
                >
                  <i class="fas fa-minus-circle" style="color: #d90a20"></i>
                </div>
              </div>
            </div>
          </section> -->

          <button type="submit" ref="submitButton" class="hidden">Save</button>
        </form>
      </ValidationObserver>
      <div class="flex justify-end items-center pr-2 mt-6">
        <slide-over-right-trigger :id="sorId">
         <anchor-button variant="secondary">{{ $t('components.stepNavigation.cancel') }}</anchor-button>

        </slide-over-right-trigger>
       <t-button type="submit" @click="submit()" class="ml-3">{{getActionButtonText}}</t-button>

      </div>
    </div>
  </slide-over-right>
</template>
<script>
import { VehicleModelConfig } from '@/config/VehicleModelConfig'
import { EventBus } from '@/utils/EventBus'
import xMan from '@/utils/xMan'
import SlideOverRight from '@/components/modals/SlideOverRight'
import TextInput from '@/components/form/TextInput'
import InputLabel from '@/components/form/InputLabel'
import AnchorButton from '@/components/form/AnchorButton'
import SlideOverRightTrigger from '@/components/modals/SlideOverRightTrigger'
import { UploadAvatar } from '@/components/form'
export default {
  name: 'VehicleModelAddEdit',
  props: {
    roleType: {
      type: String,
      required: true,
    },
  },
  components: {
    SlideOverRight,
    TextInput,
    InputLabel,
    AnchorButton,
    SlideOverRightTrigger,
    UploadAvatar,
  },
  data() {
    return {
      sorId: VehicleModelConfig.events.sorId,
      isLoading: false,
      form: {
        vehicle_image: null,
        name: '',
        title: '',
        iot_protocol: '',
        seat_capacity: '',
        swappable_battery: false,
        max_speed: '',
        max_speed_unit: 0,
        range: '',
        range_unit: 0,
        motor_power_rating: '',
        motor_power_rating_unit: 0,
        battery_capacity: '',
        battery_capacity_unit: 0,
        charging_time: '',
        charging_time_unit: 0,
        wheel_diameter: '',
        rear_wheel_diameter: '',
        wheel_diameter_unit: 0,
        net_weight: '',
        net_weight_unit: 0,
        payload_max_weight: '',
        payload_max_weight_unit: 0,
        exposed_cables: false,
        front_fork_type: '',
        kickstand_type: '',
        front_brake_types: '',
        rear_brake_types: '',
        lock_manufacturer: '',
        vehicle_manufacturer: '',
      },
      specs: [],

      item: {},
      iotProtocols: [],
      lockManufactures: [],
      vehicleManufactures: [],
      lockManufacturerId: '',
      swapOptions: [
        { text: 'True', value: true },
        { text: 'False', value: false },
      ],
      mileageOptions: [],
      speedUnitOptions: [],
      batteryUnitOptions: [],
      chargingTimeUnitOptions: [],
      motorPowerUnitOptions: [],
      weightUnitOptions: [],
      wheelDiameterUnitOptions: [],
    }
  },
  async created() {
    await this.fetchInitData()
  },
  computed: {

   getActionButtonText: function() {
      return this.isEditing
        ? this.$t('components.stepNavigation.update')
        : this.$t('components.stepNavigation.save')
    },
    
    isEditing: function() {
      // enabled status-switch by default
      return Object.keys(this.item).length > 1
    },
    titleText() {
     return this.isEditing ? this.$t('components.vehicleModelManagement.addEdit.headline.update')
        : this.$t('components.vehicleModelManagement.addEdit.headline.add')
    },
  },
  mounted() {
    EventBus.$on(VehicleModelConfig.events.editingData, (data) => {
      this.init()
      this.item = data.item
      this.iotProtocols = data.iotProtocols
      this.lockManufactures = data.lockManufactures
      this.vehicleManufactures = data.vehicleManufactures

      if (Object.keys(this.item).length > 1) {
        //edit
        this.form = {
          vehicle_image: this.item.vehicle_image,
          name: this.item.name,
          title: this.item.title,
          iot_protocol: this.item.iot_protocol,
          seat_capacity: this.item.seat_capacity,
          swappable_battery: this.item.swappable_battery,
          max_speed: this.item.max_speed,
          max_speed_unit: this.item.max_speed_unit,
          range: this.item.range,
          range_unit: this.item.range_unit,
          motor_power_rating: this.item.motor_power_rating,
          motor_power_rating_unit: this.item.motor_power_rating_unit,
          battery_capacity: this.item.battery_capacity,
          battery_capacity_unit: this.item.battery_capacity_unit,
          charging_time: this.item.charging_time,
          charging_time_unit: this.item.charging_time_unit,
          wheel_diameter: this.item.wheel_diameter,
          rear_wheel_diameter: this.item.rear_wheel_diameter,
          wheel_diameter_unit: this.item.wheel_diameter_unit,
          net_weight: this.item.net_weight,
          net_weight_unit: this.item.net_weight_unit,
          payload_max_weight: this.item.payload_max_weight,
          payload_max_weight_unit: this.item.payload_max_weight_unit,
          exposed_cables: this.item.exposed_cables,
          front_fork_type: this.item.front_fork_type,
          kickstand_type: this.item.kickstand_type,
          front_brake_types: this.item.front_brake_types,
          rear_brake_types: this.item.rear_brake_types,
          lock_manufacturer: this.item.lock_manufacturer,
          vehicle_manufacturer: this.item.vehicle_manufacturer,
        }
      } else {
        //add
        this.init()
      }
    })
  },
  methods: {
    async fetchInitData() {
      const speedUnitOptionsReq = this.$http.get(
        VehicleModelConfig.api.choices.maxSpeedUnitChoices()
      )

      const rangeUnitChoicesReq = this.$http.get(
        VehicleModelConfig.api.choices.rangeUnitChoices()
      )

      const batteryCapacityUnitChoicesReq = this.$http.get(
        VehicleModelConfig.api.choices.batteryCapacityUnitChoices()
      )
      const chargingTimeUnitChoicesReq = this.$http.get(
        VehicleModelConfig.api.choices.chargingTimeUnitChoices()
      )

      const motorPowerRatingUnitChoicesReq = this.$http.get(
        VehicleModelConfig.api.choices.motorPowerRatingUnitChoices()
      )

      const weightUnitChoicesReq = this.$http.get(
        VehicleModelConfig.api.choices.weightUnitChoices()
      )

      const wheelDiameterUnitChoicesReq = this.$http.get(
        VehicleModelConfig.api.choices.wheelDiameterUnitChoices()
      )

      const requests = [
        speedUnitOptionsReq,
        rangeUnitChoicesReq,
        batteryCapacityUnitChoicesReq,
        chargingTimeUnitChoicesReq,
        motorPowerRatingUnitChoicesReq,
        weightUnitChoicesReq,
        wheelDiameterUnitChoicesReq,
      ]

      await this.$http
        .all(requests)
        .then(
          this.$http.spread((...responses) => {
            this.speedUnitOptions = responses[0].data
            this.mileageOptions = responses[1].data
            this.batteryUnitOptions = responses[2].data
            this.chargingTimeUnitOptions = responses[3].data
            this.motorPowerUnitOptions = responses[4].data
            this.weightUnitOptions = responses[5].data
            this.wheelDiameterUnitOptions = responses[6].data

            console.log('fetchInitDataForAddEdit', responses)
          })
        )
        .catch((err) => {
          console.log('fetchInitDataForAddEdit-err', err)
          this.$notify(
            {
              group: 'generic',
              type: 'error',
              title: 'Failed to load Add/Edit initial data',
              text: err.response.data,
            },
            3000
          )
        })
    },
    showModal() {
      dispatchEvent(new Event(VehicleModelConfig.events.sorToggle))
    },
    init() {
      this.form = {
        vehicle_image: null,
        name: '',
        title: '',
        iot_protocol: '',
        seat_capacity: '',
        swappable_battery: false,
        max_speed: '',
        max_speed_unit: 0,
        range: '',
        range_unit: '',
        motor_power_rating: '',
        motor_power_rating_unit: 0,
        battery_capacity: '',
        battery_capacity_unit: 0,
        charging_time: '',
        charging_time_unit: 0,
        wheel_diameter: '',
        rear_wheel_diameter: '',
        wheel_diameter_unit: 0,
        net_weight: '',
        net_weight_unit: 0,
        payload_max_weight: '',
        payload_max_weight_unit: 0,
        exposed_cables: false,
        front_fork_type: '',
        kickstand_type: '',
        front_brake_types: '',
        rear_brake_types: '',
        lock_manufacturer: '',
        vehicle_manufacturer: '',
      }
    },
    submit() {
      this.$refs.submitButton.click()
    },
    async confirmSubmit() {
      let method = this.isEditing ? 'PATCH' : 'POST'
      let url = this.isEditing
        ? this.roleType === 'owner'
          ? VehicleModelConfig.api.org.update(this.item.id)
          : VehicleModelConfig.api.update(this.item.id)
        : VehicleModelConfig.api.create()

      const formProxy = {
        ...this.form,
      }

      if (
        typeof this.form.vehicle_image === 'string' ||
        this.form.vehicle_image == null
      ) {
        delete formProxy.vehicle_image
      }

      const propertyNames = Object.keys(formProxy)
      propertyNames.map((name) => {
        if (formProxy[name] === null) {
          delete formProxy[name]
        }
      })

      let data = new xMan(formProxy).toFormData()

      // if (this.specs.length > 0) {
      //   this.specs.map((item) => data.append('specs', item))
      // } else {
      //   data.append('specs', '')
      // }

      let message = this.isEditing
        ? 'Vehicle Model updated successfully'
        : 'Vehicle Model added successfully'

      this.isLoading = true

      try {
        let response = await this.$http({
          method,
          url,
          data,
        })

        console.log('response = ', url, '  ', method, '  ')
        console.log('data = ', response)

        this.isLoading = false
        // Close the slideover
        dispatchEvent(new Event(VehicleModelConfig.events.sorToggle))
        dispatchEvent(new Event(VehicleModelConfig.events.refresh))
        this.$emit('add-edit')
        // Reset the form
        this.init()
        this.$notify({
          group: 'bottomLeft',
          type: 'success',
          title: 'Success',
          text: message,
        })
        // Resolved
        return response.status
      } catch (error) {
        const e = error.response
        console.log('err', e)

        this.$notify(
          {
            group: 'bottomLeft',
            type: 'error',
            title: `Error Occured [Code: ${e.status}]`,
            text: e.data ?? 'The action could not be executed',
          },
          5000
        )

        this.isLoading = false
      }
    },
    onAddSpecField() {
      this.specs.unshift('')
    },
    onRemoveSpecField(index) {
      this.specs.splice(index, 1)
    },
  },
}
</script>
<style lang="scss">
.header-title {
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2e2e39;
}
</style>
