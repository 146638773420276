<template lang="">
  <div>
    <VehicleModelAddEdit
      @add-edit="$store.dispatch('fsTable/fetchData')"
      :roleType="roleType"
    />
    <content-section :spacing="false">
      <div class="content-spacing">
        <div class="grid grid-cols-1 mb-10 md:grid-cols-2">
          <TitlePlus
            :title="$t('components.vehicleModelManagement.headline')"
            @plus="add"
            :hide-plus="isHidePlus"
          />
        </div>
        <div class="summary-card-container">
          <SummaryCard
             :title="$t('components.vehicleModelManagement.summary.total')"
            :value="indexMetaData.count.total"
            variant="gray"
            size="large"
          />
        </div>
      </div>

      <FSTable
        :fst-id="fstId"
        :searchEnabled="false"
        :headers="tableHeaders"
        :endpoint="endpoint"
        :qso="qso"
        @meta="(e) => (indexMetaData = e)"
      >
        <template #default="{ data }">
          <FSTableRow
            v-for="(item, itemIndex) in data"
            :key="itemIndex"
            text-fallback-always
          >
            <FSTableRowItem
              className="text-blue-600 cursor-pointer"
              @click="edit(item)"
              :text="roleType === 'owner' ? item.title : item.name"
            />
            <FSTableRowItem :text="getProtocolName(item.iot_protocol)" />
            <FSTableRowItem
              :text="
                `${item.max_speed || '-'} ${item.max_speed_unit_display_text ||
                  '-'}`
              "
            />
            <FSTableRowItem
              :text="
                `${parseFloat(item.range) ||
                  '-'} ${item.range_unit_display_text || '-'}`
              "
            />
            <FSTableRowItem
              ><div class="flex items-center">
                <oto-edit-icon @click="edit(item)" /></div
            ></FSTableRowItem>
          </FSTableRow>
        </template>
      </FSTable>
    </content-section>
  </div>
</template>
<script>
import ContentSection from '@/components/layout/ContentSection'
import TitlePlus from '@/components/ui/TitlePlus'
import { FSTable, FSTableRow, FSTableRowItem } from '@/components/fs-table'
import VehicleModelAddEdit from '@/views/vehicle-manufacturers/VehicleModelAddEdit.vue'
import OtoEditIcon from '@/components/ui/OtoEditIcon'
import { VehicleModelConfig } from '@/config/VehicleModelConfig'
import { EventBus } from '@/utils'
import { useEndpoints } from '@/composables'
import SummaryCard from '@/components/cards/SummaryCard'
export default {
  name: 'VehicleModelIndex',
  props: {
    fstId: {
      type: String,
      default: 'vehicle-model-index',
      required: false,
    },
    qso: {
      type: Object,
      default: () => ({ append: '', prepend: '?' }),
    },
    endpoint: {
      type: String,
      default: '',
      required: false,
    },
    roleType: {
      type: String,
      default: 'admin',
      required: false,
    },
    isHidePlus: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  components: {
    ContentSection,
    TitlePlus,
    FSTable,
    FSTableRow,
    FSTableRowItem,
    OtoEditIcon,
    VehicleModelAddEdit,
    SummaryCard,
  },

  data() {
    return {
      indexMetaData: {
        count: {
          total: 0,
        },
      },
      tableHeaders: [
        { 
          text: `${this.$t('components.vehicleModelManagement.table.columns.name')}`, width: '30%', 
          sort: 'name' 
          },
        { 
          text: `${this.$t('components.vehicleModelManagement.table.columns.protocol')}`, width: '30%', 
          sort: 'protocol'
          
           },

        {  
           
        text: `${this.$t('components.vehicleModelManagement.table.columns.maxSpeed')}`, width: '20%', 
        sort: 'maxSpeed' 
        
        },

        {  
           text: `${this.$t('components.vehicleModelManagement.table.columns.mileage')}`,
        
        width: '20%', 
        
        sort: 'mileage' 
        },

        {  
          text: `${this.$t('components.vehicleModelManagement.table.columns.actions')}`, width: '10%', 
        sort: 'actions'
         },
      ],
      iotProtocols: [],
      lockManufactures: [],
      vehicleManufactures: [],
    }
  },
  async created() {
    this.iotProtocols = await this.$http
      .get(useEndpoints.dropdown.lockTypes())
      .then((res) => res.data.data)
      .catch((err) => console.log('lockTypesErr = ', err.response))

    this.lockManufactures = await this.$http
      .get(useEndpoints.dropdown.iot_manufacture())
      .then((res) => res.data.data)
      .catch((err) => console.log('iotManufactureErr = ', err.response))

    this.vehicleManufactures = await this.$http
      .get(useEndpoints.dropdown.vehicle_manufacture())
      .then((res) => res.data.data)
      .catch((err) => console.log('vehicleManufactureErr = ', err.response))
  },
  computed: {
    lockManufacturerId() {
      return this.$route.params.id
    },
  },
  methods: {
    add() {
      EventBus.$emit(VehicleModelConfig.events.editingData, {
        iotProtocols: this.iotProtocols,
        lockManufactures: this.lockManufactures,
        vehicleManufactures: this.vehicleManufactures,
        lockManufacturerId: this.lockManufacturerId,
        item: {},
      })
      dispatchEvent(new Event(VehicleModelConfig.events.sorToggle))
    },
    edit(item) {
      console.log(item)
      EventBus.$emit(VehicleModelConfig.events.editingData, {
        item: item,
        iotProtocols: this.iotProtocols,
        lockManufactures: this.lockManufactures,
        vehicleManufactures: this.vehicleManufactures,
      })
      dispatchEvent(new Event(VehicleModelConfig.events.sorToggle))
    },
    getProtocolName(protocolId) {
      const selectedProtocols = this.iotProtocols.find(
        (item) => item.value === protocolId
      )
      return selectedProtocols ? selectedProtocols.display_name : protocolId
    },
  },
}
</script>
<style lang=""></style>
