var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('slide-over-right',{attrs:{"id":_vm.sorId,"editType":"vehicle-model"},on:{"showModal":_vm.showModal}},[_c('loading',{attrs:{"active":_vm.isLoading},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('div',{staticClass:"flex flex-wrap px-6 pt-12 w-full"},[_c('div',{staticClass:"items-center  panel-title header-title"},[_vm._v(" "+_vm._s(_vm.titleText)+" ")])]),_c('div',{staticClass:"px-6"},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{attrs:{"enctype":"multipart/form-data"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.confirmSubmit)}}},[_c('UploadAvatar',{class:"mt-5",attrs:{"current":_vm.form.vehicle_image},model:{value:(_vm.form.vehicle_image),callback:function ($$v) {_vm.$set(_vm.form, "vehicle_image", $$v)},expression:"form.vehicle_image"}}),_c('div',{staticClass:"flex items-center pt-2"},[_c('div',{staticClass:"pr-2 w-1/2"},[(_vm.roleType === 'owner')?_c('text-input',{attrs:{"rules":"required","label":_vm.$t(
                'components.vehicleModelManagement.addEdit.steps.profile.title.name'
              ),"placeholder":"e.g. Segway MAX Plus"},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}}):_c('text-input',{attrs:{"rules":"required","label":_vm.$t(
                'components.vehicleModelManagement.addEdit.steps.profile.title.modelName'
              ),"placeholder":"e.g. Segway MAX Plus"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('div',{staticClass:"pl-2 w-1/2"},[_c('AppInput',{attrs:{"type":"richselect","name":_vm.$t(
                'components.vehicleModelManagement.addEdit.steps.profile.title.iotProtocol'
              ),"label":_vm.$t(
                'components.vehicleModelManagement.addEdit.steps.profile.title.iotProtocol'
              ),"rules":"required","disabled":_vm.roleType === 'owner' ? true : false,"placeholder":_vm.$t(
                  'components.vehicleModelManagement.addEdit.steps.profile.placeholder.iotProtocol'
                ),"value-attribute":"value","text-attribute":"display_name","isInfoEnabled":false,"options":_vm.iotProtocols},model:{value:(_vm.form.iot_protocol),callback:function ($$v) {_vm.$set(_vm.form, "iot_protocol", $$v)},expression:"form.iot_protocol"}})],1)]),_c('div',{staticClass:"flex items-center pt-2"},[_c('div',{staticClass:"pr-2 w-1/2"},[_c('AppInput',{attrs:{"type":"richselect","name":_vm.$t(
                'components.vehicleModelManagement.addEdit.steps.profile.title.lockManufacture'
              ),"label":_vm.$t(
                'components.vehicleModelManagement.addEdit.steps.profile.title.lockManufacture'
              ),"rules":"required","disabled":_vm.roleType === 'owner' ? true : false,"placeholder":_vm.$t(
                  'components.vehicleModelManagement.addEdit.steps.profile.placeholder.lockManufacture'
                ),"value-attribute":"id","text-attribute":"name","isInfoEnabled":false,"options":_vm.lockManufactures},model:{value:(_vm.form.lock_manufacturer),callback:function ($$v) {_vm.$set(_vm.form, "lock_manufacturer", $$v)},expression:"form.lock_manufacturer"}})],1),_c('div',{staticClass:"pl-2 w-1/2"},[_c('AppInput',{attrs:{"type":"richselect","name":_vm.$t(
                'components.vehicleModelManagement.addEdit.steps.profile.title.vehicleManufacture'
              ),"label":_vm.$t(
                'components.vehicleModelManagement.addEdit.steps.profile.title.vehicleManufacture'
              ),"rules":"required","disabled":_vm.roleType === 'owner' ? true : false,"placeholder":_vm.$t(
                  'components.vehicleModelManagement.addEdit.steps.profile.placeholder.vehicleManufacture'
                ),"value-attribute":"id","text-attribute":"name","isInfoEnabled":false,"options":_vm.vehicleManufactures},model:{value:(_vm.form.vehicle_manufacturer),callback:function ($$v) {_vm.$set(_vm.form, "vehicle_manufacturer", $$v)},expression:"form.vehicle_manufacturer"}})],1)]),_c('div',{staticClass:"flex items-center pt-2"},[_c('div',{staticClass:"pr-2 w-1/2"},[_c('text-input',{attrs:{"rules":"","label":_vm.$t(
                'components.vehicleModelManagement.addEdit.steps.profile.title.seatCapacity'
              ),"type":"number","placeholder":"e.g. 3"},model:{value:(_vm.form.seat_capacity),callback:function ($$v) {_vm.$set(_vm.form, "seat_capacity", $$v)},expression:"form.seat_capacity"}})],1),_c('div',{staticClass:"pl-2 w-1/2"},[_c('ValidationProvider',{attrs:{"name":_vm.$t(
                'components.vehicleModelManagement.addEdit.steps.profile.title.swappableBatteryStatus'
              ),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('input-label',{attrs:{"error":errors[0],"text":_vm.$t(
                  'components.vehicleModelManagement.addEdit.steps.profile.title.swappableBatteryStatus'
                )}}),_c('t-rich-select',{attrs:{"placeholder":"e.g. True","options":_vm.swapOptions,"valueAttribute":"value","textAttribute":"text","hideSearchBox":true,"variant":{ danger: !!errors[0] }},model:{value:(_vm.form.swappable_battery),callback:function ($$v) {_vm.$set(_vm.form, "swappable_battery", $$v)},expression:"form.swappable_battery"}}),_c('input-error-item',{attrs:{"message":errors[0]}})]}}],null,true)})],1)]),_c('div',{staticClass:"flex items-center pt-2"},[_c('div',{staticClass:"pr-2 w-1/2"},[_c('text-input',{attrs:{"rules":"","label":_vm.$t(
                'components.vehicleModelManagement.addEdit.steps.profile.title.maxSpeed'
              ),"type":"number","placeholder":"e.g. 25","step":"0.01"},model:{value:(_vm.form.max_speed),callback:function ($$v) {_vm.$set(_vm.form, "max_speed", $$v)},expression:"form.max_speed"}})],1),_c('div',{staticClass:"pl-2 w-1/2"},[_c('ValidationProvider',{attrs:{"name":_vm.$t(
                'components.vehicleModelManagement.addEdit.steps.profile.title.maxSpeedUnit'
              ),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('input-label',{attrs:{"error":errors[0],"text":_vm.$t(
                'components.vehicleModelManagement.addEdit.steps.profile.title.maxSpeedUnit'
              )}}),_c('t-rich-select',{attrs:{"placeholder":"e.g. kmph","options":_vm.speedUnitOptions,"valueAttribute":"value","textAttribute":"text","hideSearchBox":true,"variant":{ danger: !!errors[0] }},model:{value:(_vm.form.max_speed_unit),callback:function ($$v) {_vm.$set(_vm.form, "max_speed_unit", $$v)},expression:"form.max_speed_unit"}}),_c('input-error-item',{attrs:{"message":errors[0]}})]}}],null,true)})],1)]),_c('div',{staticClass:"flex items-center pt-2"},[_c('div',{staticClass:"pr-2 w-1/2"},[_c('text-input',{attrs:{"rules":"","label":_vm.$t(
                'components.vehicleModelManagement.addEdit.steps.profile.title.mileage'
              ),"type":"number","placeholder":"e.g. 100"},model:{value:(_vm.form.range),callback:function ($$v) {_vm.$set(_vm.form, "range", $$v)},expression:"form.range"}})],1),_c('div',{staticClass:"pl-2 w-1/2"},[_c('ValidationProvider',{attrs:{"name":_vm.$t(
                'components.vehicleModelManagement.addEdit.steps.profile.title.mileageUnit'
              ),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('input-label',{attrs:{"error":errors[0],"text":_vm.$t(
                'components.vehicleModelManagement.addEdit.steps.profile.title.mileageUnit'
              )}}),_c('t-rich-select',{attrs:{"placeholder":"e.g. Mile","options":_vm.mileageOptions,"valueAttribute":"value","textAttribute":"text","hideSearchBox":true,"variant":{ danger: !!errors[0] }},model:{value:(_vm.form.range_unit),callback:function ($$v) {_vm.$set(_vm.form, "range_unit", $$v)},expression:"form.range_unit"}}),_c('input-error-item',{attrs:{"message":errors[0]}})]}}],null,true)})],1)]),_c('div',{staticClass:"flex items-center pt-2"},[_c('div',{staticClass:"pr-2 w-1/2"},[_c('text-input',{attrs:{"rules":"","label":_vm.$t(
                'components.vehicleModelManagement.addEdit.steps.profile.title.motorPowerRating'
              ),"placeholder":"e.g. 350"},model:{value:(_vm.form.motor_power_rating),callback:function ($$v) {_vm.$set(_vm.form, "motor_power_rating", $$v)},expression:"form.motor_power_rating"}})],1),_c('div',{staticClass:"pl-2 w-1/2"},[_c('ValidationProvider',{attrs:{"name":_vm.$t(
                'components.vehicleModelManagement.addEdit.steps.profile.title.motorPowerRatingUnit'
              ),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('input-label',{attrs:{"error":errors[0],"text":_vm.$t(
                'components.vehicleModelManagement.addEdit.steps.profile.title.motorPowerRatingUnit'
              )}}),_c('t-rich-select',{attrs:{"options":_vm.motorPowerUnitOptions,"valueAttribute":"value","textAttribute":"text","hideSearchBox":true,"variant":{ danger: !!errors[0] }},model:{value:(_vm.form.motor_power_rating_unit),callback:function ($$v) {_vm.$set(_vm.form, "motor_power_rating_unit", $$v)},expression:"form.motor_power_rating_unit"}}),_c('input-error-item',{attrs:{"message":errors[0]}})]}}],null,true)})],1)]),_c('div',{staticClass:"flex items-center pt-2"},[_c('div',{staticClass:"pr-2 w-1/2"},[_c('text-input',{attrs:{"rules":"","label":_vm.$t(
                'components.vehicleModelManagement.addEdit.steps.profile.title.batteryCapacity'
              ),"placeholder":"e.g. 864"},model:{value:(_vm.form.battery_capacity),callback:function ($$v) {_vm.$set(_vm.form, "battery_capacity", $$v)},expression:"form.battery_capacity"}})],1),_c('div',{staticClass:"pl-2 w-1/2"},[_c('ValidationProvider',{attrs:{"name":_vm.$t(
                'components.vehicleModelManagement.addEdit.steps.profile.title.batteryCapacityUnit'
              ),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('input-label',{attrs:{"error":errors[0],"text":_vm.$t(
                'components.vehicleModelManagement.addEdit.steps.profile.title.batteryCapacityUnit'
              )}}),_c('t-rich-select',{attrs:{"options":_vm.batteryUnitOptions,"valueAttribute":"value","textAttribute":"text","hideSearchBox":true,"variant":{ danger: !!errors[0] }},model:{value:(_vm.form.battery_capacity_unit),callback:function ($$v) {_vm.$set(_vm.form, "battery_capacity_unit", $$v)},expression:"form.battery_capacity_unit"}}),_c('input-error-item',{attrs:{"message":errors[0]}})]}}],null,true)})],1)]),_c('div',{staticClass:"flex items-center pt-2"},[_c('div',{staticClass:"pr-2 w-1/2"},[_c('text-input',{attrs:{"rules":"","label":_vm.$t(
                'components.vehicleModelManagement.addEdit.steps.profile.title.chargingTime'
              ),"placeholder":"e.g. 7"},model:{value:(_vm.form.charging_time),callback:function ($$v) {_vm.$set(_vm.form, "charging_time", $$v)},expression:"form.charging_time"}})],1),_c('div',{staticClass:"pl-2 w-1/2"},[_c('ValidationProvider',{attrs:{"name":_vm.$t(
                'components.vehicleModelManagement.addEdit.steps.profile.title.chargingTimeUnit'
              ),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('input-label',{attrs:{"error":errors[0],"text":_vm.$t(
                'components.vehicleModelManagement.addEdit.steps.profile.title.chargingTimeUnit'
              )}}),_c('t-rich-select',{attrs:{"options":_vm.chargingTimeUnitOptions,"valueAttribute":"value","textAttribute":"text","hideSearchBox":true,"variant":{ danger: !!errors[0] }},model:{value:(_vm.form.charging_time_unit),callback:function ($$v) {_vm.$set(_vm.form, "charging_time_unit", $$v)},expression:"form.charging_time_unit"}}),_c('input-error-item',{attrs:{"message":errors[0]}})]}}],null,true)})],1)]),_c('div',{staticClass:"flex items-center pt-2"},[_c('div',{staticClass:"pr-2 w-1/2"},[_c('text-input',{attrs:{"rules":"","label":_vm.$t(
                'components.vehicleModelManagement.addEdit.steps.profile.title.vehicleWeight'
              ),"placeholder":"e.g. 7"},model:{value:(_vm.form.net_weight),callback:function ($$v) {_vm.$set(_vm.form, "net_weight", $$v)},expression:"form.net_weight"}})],1),_c('div',{staticClass:"pl-2 w-1/2"},[_c('ValidationProvider',{attrs:{"name":_vm.$t(
                'components.vehicleModelManagement.addEdit.steps.profile.title.vehicleWeightUnit'
              ),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('input-label',{attrs:{"error":errors[0],"text":_vm.$t(
                'components.vehicleModelManagement.addEdit.steps.profile.title.vehicleWeightUnit'
              )}}),_c('t-rich-select',{attrs:{"options":_vm.weightUnitOptions,"valueAttribute":"value","textAttribute":"text","hideSearchBox":true,"variant":{ danger: !!errors[0] }},model:{value:(_vm.form.net_weight_unit),callback:function ($$v) {_vm.$set(_vm.form, "net_weight_unit", $$v)},expression:"form.net_weight_unit"}}),_c('input-error-item',{attrs:{"message":errors[0]}})]}}],null,true)})],1)]),_c('div',{staticClass:"flex items-center pt-2"},[_c('div',{staticClass:"pr-2 w-1/2"},[_c('text-input',{attrs:{"rules":"","label":_vm.$t(
                'components.vehicleModelManagement.addEdit.steps.profile.title.loadCapacity'
              ),"placeholder":"e.g. 7"},model:{value:(_vm.form.payload_max_weight),callback:function ($$v) {_vm.$set(_vm.form, "payload_max_weight", $$v)},expression:"form.payload_max_weight"}})],1),_c('div',{staticClass:"pl-2 w-1/2"},[_c('ValidationProvider',{attrs:{"name":_vm.$t(
                'components.vehicleModelManagement.addEdit.steps.profile.title.loadCapacityUnit'
              ),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('input-label',{attrs:{"error":errors[0],"text":_vm.$t(
                'components.vehicleModelManagement.addEdit.steps.profile.title.loadCapacityUnit'
              )}}),_c('t-rich-select',{attrs:{"options":_vm.weightUnitOptions,"valueAttribute":"value","textAttribute":"text","hideSearchBox":true,"variant":{ danger: !!errors[0] }},model:{value:(_vm.form.payload_max_weight_unit),callback:function ($$v) {_vm.$set(_vm.form, "payload_max_weight_unit", $$v)},expression:"form.payload_max_weight_unit"}}),_c('input-error-item',{attrs:{"message":errors[0]}})]}}],null,true)})],1)]),_c('div',{staticClass:"flex items-center pt-2"},[_c('div',{staticClass:"pr-2 w-1/2"},[_c('text-input',{attrs:{"rules":"","label":_vm.$t(
                'components.vehicleModelManagement.addEdit.steps.profile.title.frontWheelDiameter'
              ),"placeholder":"e.g. 10"},model:{value:(_vm.form.wheel_diameter),callback:function ($$v) {_vm.$set(_vm.form, "wheel_diameter", $$v)},expression:"form.wheel_diameter"}})],1),_c('div',{staticClass:"pl-2 w-1/2"},[_c('text-input',{attrs:{"rules":"","label":_vm.$t(
                'components.vehicleModelManagement.addEdit.steps.profile.title.rearWheelDiameter'
              ),"placeholder":"e.g. 7"},model:{value:(_vm.form.rear_wheel_diameter),callback:function ($$v) {_vm.$set(_vm.form, "rear_wheel_diameter", $$v)},expression:"form.rear_wheel_diameter"}})],1)]),_c('div',{staticClass:"flex items-center pt-2"},[_c('div',{staticClass:"pr-2 w-1/2"},[_c('ValidationProvider',{attrs:{"name":_vm.$t(
                'components.vehicleModelManagement.addEdit.steps.profile.title.wheelDiameterUnit'
              ),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('input-label',{attrs:{"error":errors[0],"text":_vm.$t(
                'components.vehicleModelManagement.addEdit.steps.profile.title.wheelDiameterUnit'
              )}}),_c('t-rich-select',{attrs:{"options":_vm.wheelDiameterUnitOptions,"valueAttribute":"value","textAttribute":"text","hideSearchBox":true,"variant":{ danger: !!errors[0] }},model:{value:(_vm.form.wheel_diameter_unit),callback:function ($$v) {_vm.$set(_vm.form, "wheel_diameter_unit", $$v)},expression:"form.wheel_diameter_unit"}}),_c('input-error-item',{attrs:{"message":errors[0]}})]}}],null,true)})],1),_c('div',{staticClass:"pl-2 w-1/2"},[_c('text-input',{attrs:{"rules":"","label":_vm.$t(
                'components.vehicleModelManagement.addEdit.steps.profile.title.frontForkType'
              ),"placeholder":""},model:{value:(_vm.form.front_fork_type),callback:function ($$v) {_vm.$set(_vm.form, "front_fork_type", $$v)},expression:"form.front_fork_type"}})],1)]),_c('div',{staticClass:"flex items-center pt-2"},[_c('div',{staticClass:"pr-2 w-1/2"},[_c('text-input',{attrs:{"rules":"","label":_vm.$t(
                'components.vehicleModelManagement.addEdit.steps.profile.title.frontBrakeTypes'
              ),"placeholder":""},model:{value:(_vm.form.front_brake_types),callback:function ($$v) {_vm.$set(_vm.form, "front_brake_types", $$v)},expression:"form.front_brake_types"}})],1),_c('div',{staticClass:"pl-2 w-1/2"},[_c('text-input',{attrs:{"rules":"","label":_vm.$t(
                'components.vehicleModelManagement.addEdit.steps.profile.title.rearBrakeTypes'
              ),"placeholder":""},model:{value:(_vm.form.rear_brake_types),callback:function ($$v) {_vm.$set(_vm.form, "rear_brake_types", $$v)},expression:"form.rear_brake_types"}})],1)]),_c('div',{staticClass:"flex items-center pt-2"},[_c('div',{staticClass:"pr-2 w-1/2"},[_c('text-input',{attrs:{"rules":"","label":_vm.$t(
                'components.vehicleModelManagement.addEdit.steps.profile.title.kickStandType'
              ),"placeholder":""},model:{value:(_vm.form.kickstand_type),callback:function ($$v) {_vm.$set(_vm.form, "kickstand_type", $$v)},expression:"form.kickstand_type"}})],1),_c('div',{staticClass:"pl-2 w-1/2"},[_c('ValidationProvider',{attrs:{"name":_vm.$t(
                'components.vehicleModelManagement.addEdit.steps.profile.title.exposedCables'
              ),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('input-label',{attrs:{"error":errors[0],"text":"Exposed Cables"}}),_c('t-rich-select',{attrs:{"options":[
                  { text: 'True', value: true },
                  { text: 'False', value: false } ],"valueAttribute":"value","textAttribute":"text","hideSearchBox":true,"variant":{ danger: !!errors[0] }},model:{value:(_vm.form.exposed_cables),callback:function ($$v) {_vm.$set(_vm.form, "exposed_cables", $$v)},expression:"form.exposed_cables"}}),_c('input-error-item',{attrs:{"message":errors[0]}})]}}],null,true)})],1)]),_c('button',{ref:"submitButton",staticClass:"hidden",attrs:{"type":"submit"}},[_vm._v("Save")])],1)]}}])}),_c('div',{staticClass:"flex justify-end items-center pr-2 mt-6"},[_c('slide-over-right-trigger',{attrs:{"id":_vm.sorId}},[_c('anchor-button',{attrs:{"variant":"secondary"}},[_vm._v(_vm._s(_vm.$t('components.stepNavigation.cancel')))])],1),_c('t-button',{staticClass:"ml-3",attrs:{"type":"submit"},on:{"click":function($event){return _vm.submit()}}},[_vm._v(_vm._s(_vm.getActionButtonText))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }